import { createStore } from 'vuex'
import user from './modules/user'
import dict from './modules/dict'
import price from './modules/price'
import quality from './modules/quality'

export default createStore({
    state: {
        imageUrl: ''
    },
    mutations: {},
    actions: {},
    modules: {
        user,
        dict,
        price,
        quality
    }
})
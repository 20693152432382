<template>
  <div id="nav">
    <v-header />
    <div :style="isMobile()?'':'margin-top:55px;'"></div>
    <router-view />
    <v-footer />
  </div>
</template>

<script>
  import Header from './components/Header'
  import Footer from './components/Footer'
  import MobileHeader from './components/mobile/Header'
  import MobileFooter from './components/mobile/Footer'
  export default {
    name: 'App',
    components: {
      'v-header': document.body.clientWidth < 500 ? MobileHeader : Header,
      'v-footer': document.body.clientWidth < 500 ? MobileFooter : Footer
    },
    mounted () {
      this.getBaseInfo();
    },
    methods: {
      isMobile () {
        return document.body.clientWidth < 500
      },
      async getBaseInfo() {
        let params = {
          productNo: 'YXBB02',
        }
       await this.$store
          .dispatch('dict/baseInfo', params)
          .then((res) => {
            console.log(res)
          })
          .catch((err) => {
            console.log(err)
          })
      },
    }
  }
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
</style>
import {
	qualityInfo,
	saveQualityInfo
} from '@/api/quality.js'
export default {
	namespaced: true,
	state: {
		qualityInfoList: []
	},
	getters: {
		getQualityInfoList(state) {
			return state.qualityInfoList
		}
	},
	mutations: {
		setQualityInfoList(state, qualityInfoList) {
			state.qualityInfoList = qualityInfoList;
		}
	},
	actions: {
		qualityInfoList({
			commit,
		}, params) {
			return new Promise((resolve, reject) => {
				qualityInfo(params).then(response => {
					commit('setQualityInfoList', response);
					resolve(response)
				}).catch(error => {
					reject(error)
				})
			})
		},
		saveQualityInfo(params) {
			return new Promise((resolve, reject) => {
				saveQualityInfo(params).then(response => {
					resolve(response)
				}).catch(error => {
					reject(error)
				})
			})
		}
	}

}

import axios from 'axios';



export default {
	post (url, data) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'POST',
				url: url,
				params: data ? data : '',
				header: {
					'content-type': 'application/json;charset:utf-8'
				},
			})
				.then((res) => {
					resolve(res.data)
				}).catch((err) => {
					reject(err);
				})
		});
	},
	get (url, data) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'GET',
				url: url,
				params: data ? data : '',
				header: {
					'content-type': 'application/json;charset:utf-8'
				},
			})
				.then((res) => {
					resolve(res.data)
				}).catch((err) => {
					reject(err);
				})
		})
	},
	put (url, data) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'PUT',
				url: url,
				params: data ? data : '',
			})
				.then((res) => {
					resolve(res.data)
				}).catch((err) => {
					reject(err);
				})
		})
	},
	delete (url, data) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'PUT',
				url: url,
				params: data ? data : '',
			})
				.then((res) => {
					resolve(res.data)
				}).catch((err) => {
					console.log(err)
					reject();
				})
		})
	}
}
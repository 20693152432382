import request from '@/utils/request.js'

export function qualityInfo(params) {
	// let url = process.env.VUE_APP_BASE_URL + "/wechat/getQualityInfo"
	let url = "https://yeemcloud.com/car-api/wechat/getQualityInfo"
	return new Promise((resolve, reject) => {
		request.get(url, params).then((res) => {
			resolve(res);
		}).catch((err) => {
			reject(err)
		})
	})
}

export function saveQualityInfo(params) {
	// let url = process.env.VUE_APP_BASE_URL + "/wechat/saveQualityInfo"
	let url = "https://yeemcloud.com/car-api/wechat/saveQualityInfo"
	return new Promise((resolve, reject) => {
		request.post(url, params).then((res) => {
			resolve(res);
		}).catch((err) => {
			reject(err)
		})
	})
}
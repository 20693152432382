import {
	login,
	update
} from '@/api/user.js'
export default {
	namespaced: true,
	state: {
		token: null,
		userInfo: {}
	},
	getters: {
		getUserInfo(state) {
			return state.userInfo;
		}
	},
	mutations: {
		setUserInfo(state, userInfo) {
			state.userInfo = userInfo;
			if (userInfo.token) {
				state.token = userInfo.token;
			}
		}
	},
	actions: {
		login({
			commit,
			state
		}) {
			return new Promise((resolve, reject) => {
				login().then(response => {
					console.log(response)
					commit('setUserInfo', response);
					resolve(response)
				}).catch(error => {
					reject(error)
				})
			})
		},
		update({
			commit,
			state
		}, params) {
			return new Promise((resolve, reject) => {
				update(params).then(response => {
					resolve(response)
					commit('setUserInfo', response);
				}).catch(error => {
					reject(error)
				})
			})
		}
	}
}
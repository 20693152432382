<template>
  <div>
    <p style="font-size:13px; text-align:center; margin-top:10px">
      2020-2023 © CHINAYBOP.COM All Rights Reserved.
      <br />
      <a
        style="text-align:center; font-size:13px;"
        href="https://beian.miit.gov.cn/#/Integrated/index"
      >吉ICP备2021008123号-2</a>
    </p>
  </div>
</template>

<script>
</script>

<style scoped>
</style>

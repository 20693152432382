import { createRouter, createWebHistory } from 'vue-router'

const routes = [{
        path: '/',
        name: 'Home',
        component: isMobile() ? () =>
            import ('../views/Mobile/Home.vue') : () =>
            import ('../views/Home.vue')
    },
    {
        path: '/home',
        name: 'TrueHome',
        component: isMobile() ? () =>
            import ('../views/Mobile/Home.vue') : () =>
            import ('../views/Home.vue')
    },
    {
        path: '/about',
        name: 'About',
        component: isMobile() ? () =>
            import ('../views/Mobile/About.vue') : () =>
            import ('../views/About.vue')

    },
    {
        path: '/productLux',
        name: 'ProductLux',
        component: isMobile() ? () =>
            import ('../views/Mobile/ProductLux.vue') : () =>
            import ('../views/ProductLux.vue')

    },
    {
        path: '/productPro',
        name: 'ProductPro',
        component: isMobile() ? () =>
            import ('../views/Mobile/ProductPro.vue') : () =>
            import ('../views/ProductPro.vue')

    },
    {
        path: '/productMax',
        name: 'ProductMax',
        component: isMobile() ? () =>
            import ('../views/Mobile/ProductMax.vue') : () =>
            import ('../views/ProductMax.vue')
    },
    {
        path: '/productTop',
        name: 'ProductTop',
        component: isMobile() ? () =>
            import ('../views/Mobile/ProductTop.vue') : () =>
            import ('../views/ProductTop.vue')
    },
    {
        path: '/quality',
        name: 'Quality',
        component: isMobile() ? () =>
            import ('../views/Mobile/Quality.vue') : () =>
            import ('../views/Quality.vue')
    },
    {
        path: '/news',
        name: 'News',
        component: () =>
            import ('../views/News.vue')
    },
    {
        path: '/newsDetail',
        name: 'NewsDetail',
        component: () =>
            import ('../views/NewsDetail.vue')
    },
    {
        path: '/price',
        name: 'Price',
        component: isMobile() ? () =>
            import ('../views/Mobile/Price.vue') : () =>
            import ('../views/Price.vue')
    },
    {
        path: '/contact',
        name: 'Contact',
        component: isMobile() ? () =>
            import ('../views/Mobile/Contact.vue') : () =>
            import ('../views/Contact.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

function isMobile() {
    let flag = document.body.clientWidth < 500
    return flag
}

export default router
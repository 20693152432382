import {
	baseInfo
} from '@/api/dict.js'
export default {
	namespaced: true,
	state: {
		//窗膜
		windowProductList: [],
		//车膜
		bodyProductList: [],
		channelRetailList: [],
		channelGroupList: [],
		brandList: []
	},
	getters: {},
	mutations: {
		setWindowProductList (state, windowProductList) {
			state.windowProductList = windowProductList
		},
		setBodyProductList (state, bodyProductList) {
			state.bodyProductList = bodyProductList
		},
		setChannelRetailList (state, channelRetailList) {
			state.channelRetailList = channelRetailList
		},
		setChannelGroupList (state, channelGroupList) {
			state.channelGroupList = channelGroupList
		},
		setBrandList (state, brandList) {
			state.brandList = brandList
		}
	},
	actions: {
		baseInfo ({
			commit
		}, params) {
			return new Promise((resolve, reject) => {
				baseInfo(params).then(response => {
					commit('setBodyProductList',
						response.productList.filter(e => String(e.productType) == "隐形车衣"))
					commit('setChannelRetailList',
						response.productList.filter(e => e.channel == "零售渠道"))
					commit('setChannelGroupList',
						response.productList.filter(e => e.channel == "集团PDI渠道"))
					commit('setWindowProductList',
						response.productList.filter(e => e.productType == '汽车窗膜'))
					commit('setBrandList', response.brandList)
					resolve(response)
				}).catch(error => {
					reject(error)
				})
			})
		}
	},
}
<template>
  <div class="foot u-bg">
    <div class="row" style="padding-top: 20px;">
      <div class="col-2"></div>
      <div class="col-3">
        <p style="color:#fff; text-align:left; font-weight: bold;">关于我们</p>
        <hr color="#ffffff" width="16%" align="left" />
        <p style="color:#fff; text-align:left; font-size:14px;">超厚抗剐蹭，暴晒不龟裂</p>
        <p style="color:#fff; text-align:left; font-size:14px;">十年不变黄，十年不变哑</p>
        <p style="color:#fff; text-align:left; font-size:14px;">揭膜不留残胶，官方十年质保</p>
      </div>
      <div class="col-3">
        <p style="color:#fff; text-align:left; font-weight: bold;">站内导航</p>
        <hr color="#ffffff" width="25%" align="left" />
        <p
          style="color:#fff; text-align:left; font-size:14px; font-weight: bold; margin:0px 0px 0px 0px; cursor: pointer;"
          @click="switchMenu('productTop')"
        >产品中心</p>
        <hr color="#666" width="50%" style="margin:5px 0px 5px 0px;" />
        <p
          style="color:#fff; text-align:left; font-size:14px; font-weight: bold; margin:0px 0px 0px 0px; cursor: pointer;"
          @click="switchMenu('about')"
        >关于我们</p>
        <hr color="#666" width="50%" style="margin:5px 0px 5px 0px;" />
        <p
          style="color:#fff; text-align:left; font-size:14px; font-weight: bold; margin:0px 0px 0px 0px; cursor: pointer;"
          @click="switchMenu('news')"
        >新闻中心</p>
        <hr color="#666" width="50%" style="margin:5px 0px 5px 0px;" />
        <!-- <p
          style="color:#fff; text-align:left; font-size:14px; font-weight: bold; margin:0px 0px 0px 0px;"
        >案例中心</p>
        <hr color="#666" width="50%" style="margin:5px 0px 5px 0px;" /> -->
      </div>
      <div class="col-4">
        <p style="color:#fff; text-align:left; font-weight: bold;">联系方式</p>
        <hr color="#ffffff" width="16%" align="left" />
        <p style="color:#fff; text-align:left; font-size:14px;">
          <span style="font-weight: bold;">地址：</span>北京市朝阳区五方天雅汽车用品大厅3088-3090
        </p>
        <p style="color:#fff; text-align:left; font-size:14px;">
          <span style="font-weight: bold;">电话：</span>400 182 7879
        </p>
        <p style="color:#fff; text-align:left; font-size:14px;">
          <span style="font-weight: bold;">官网：</span>www.chinaybop.com
        </p>
      </div>
    </div>
    <hr color="#ffffff" />
    <div class="row">
      <div class="col-2"></div>
      <div class="col-10">
        <p style="color:#fff; font-size:13px; text-align:left;">
          2020-2023 © CHINAYBOP.COMAll Rights Reserved. 隐形保镖高性能汽车膜
          <a
            style="margin-left: 50px; color:#fff; font-size:13px;"
            href="https://beian.miit.gov.cn/#/Integrated/index"
          >吉ICP备2021008123号-2</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Foot',
  methods: {
    switchMenu(path) {
      this.$router.push({ path: path })
    }
  }
}
</script>

<style scoped>
@import '../assets/css/footer.css';
</style>

<template>
  <div class="head">
    <nav class="navbar fixed-top navbar-expand navbar-dark bg-dark" style="height: 58px;">
      <a class="navbar-brand logo-navbar" href="#">
        <img src="../assets/img/logo.png" width="150" height="30" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarTogglerDemo02"
        aria-controls="navbarTogglerDemo02"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class>
        <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
          <li class="nav-item">
            <a class="head-item__link" href="/" @click="switchMenu('/')">首页</a>
          </li>
          <li class="nav-item dropdown">
            <a class="head-item__link" href="#">产品中心</a>
            <div class="dropdown-menu bg-dark">
              <a
                class="head-item__link"
                href="productTop"
                @click="switchMenu('/productTop')"
              >隐形保镖 TOP系列</a>
              <a
                class="head-item__link"
                href="productPro"
                @click="switchMenu('/productPro')"
              >隐形保镖 PRO系列</a>
              <a
                class="head-item__link"
                href="productMax"
                @click="switchMenu('/productMax')"
              >隐形保镖 MAX系列</a>
              <a
                class="head-item__link"
                href="productLux"
                @click="switchMenu('/productLux')"
              >隐形保镖 LUX系列</a>
            </div>
          </li>
          <li class="nav-item">
            <a class="head-item__link" href="news">新闻中心</a>
          </li>
          <!-- <li class="nav-item">
            <a class="head-item__link" href="#">案例中心</a>
          </li> -->
          <li class="nav-item">
            <a class="head-item__link" href="quality">质保查询</a>
          </li>
          <li class="nav-item">
            <a class="head-item__link" href="price">报价查询</a>
          </li>
          <li class="nav-item">
            <a class="head-item__link" href="about">关于我们</a>
          </li>
          <li class="nav-item">
            <a class="head-item__link" href="contact">联系我们</a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'Head',
  methods: {
    switchMenu(path) {
      this.$router.push({ path: path })
    }
  }
}
</script>

<style scoped>
@import '../assets/css/header.css';
</style>

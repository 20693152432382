import request from '@/utils/request.js'

export function baseInfo (params) {
	let url = "https://yeemcloud.com/car-api/wechat/getBaseInfo"
	console.log(process.env.VUE_APP_BASE_URL);
	return new Promise((resolve, reject) => {
		request.get(url, params).then((res) => {
			resolve(res);
		}).catch((err) => {
			reject(err)
		})
	})
}
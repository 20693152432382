import request from '@/utils/request.js'

export function login() {
	let url = process.env.VUE_APP_BASE_URL + "/wechat/login"
	return new Promise((resolve, reject) => {
		uni.login({
			success(uniLoginRes) {
				if (uniLoginRes.code) {
					let data = {
						code: uniLoginRes.code,
						productNo: 'YXBB02'
					}
					request.post(url, data).then((res) => {
						resolve(res);
					}).catch((err) => {
						reject(err)
					})
				} else {
					reject()
				}
			},
			fail(err) {
				reject(err)
			}
		});
	})
}

export function update(params) {
	let url = process.env.VUE_APP_BASE_URL + "/wechat/updateUserInfo"
	return new Promise((resolve, reject) => {
		request.put(url, params).then((res) => {
			resolve(res);
		}).catch((err) => {
			reject(err)
		})
	})
}